// @file helpers for handling time
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { captureException } from '@@/bits/error_tracker'
import type { DateDisplayAs, DateDisplayFormat } from '@@/types'

/**
 * Converts seconds to time format string
 * @example if you input 71, output would be 01:11
 * @param seconds
 * @param shouldRoundSeconds
 * @returns {string} - formatted time string
 */
const secondsToSemiColonedText = function (seconds: number, shouldRoundSeconds: boolean = false): string {
  const totalMinutes = Math.floor(seconds / 60)
  const leftoverSeconds = shouldRoundSeconds ? Math.round(seconds % 60) : seconds % 60
  const minutesText = totalMinutes < 10 ? '0' + totalMinutes : '' + totalMinutes
  const secondsText = leftoverSeconds < 10 ? '0' + leftoverSeconds : '' + leftoverSeconds
  return `${minutesText}:${secondsText}`
}

function getDateStringForDisplay(datetime: string | number | Date): string {
  const date = new Date(datetime)
  let formattedTime = date.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  })
  let formattedDate = date.toLocaleString(undefined, {
    year: 'numeric',
    day: 'numeric',
    month: 'long',
  })
  try {
    formattedTime = date.toLocaleString(getHyphenatedCurrentLocale(), {
      hour: 'numeric',
      minute: 'numeric',
    })
    formattedDate = date.toLocaleString(getHyphenatedCurrentLocale(), {
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    })
  } catch (e) {
    captureException(e, {
      context: {
        source: 'getDateStringForDisplay',
        locale: getHyphenatedCurrentLocale(),
      },
    })
  }
  return `${formattedTime} • ${formattedDate}`
}

function getCustomDateFieldStringForDisplay({
  dateDisplayFormat,
  showTime,
  datetime,
  displayAs = 'dayMonthYear',
  locale = getHyphenatedCurrentLocale(),
  timezone,
}: {
  dateDisplayFormat: DateDisplayFormat
  showTime: boolean
  displayAs: DateDisplayAs
  datetime?: string | number | Date
  locale?: string
  timezone?: string
}): string {
  const date = datetime != null ? new Date(datetime) : new Date()
  const showMonth = showTime || (!showTime && (displayAs === 'monthYear' || displayAs === 'dayMonthYear'))
  const showDay = showTime || (!showTime && displayAs === 'dayMonthYear')

  // for handling iso string when showTime is false
  const isoStringNoTime = {
    dayMonthYear: date.toISOString().slice(0, 10), // 2021-08-12
    monthYear: date.toISOString().slice(0, 7), // 2021-08
    year: date.toISOString().slice(0, 4), // 2021
  }

  const mapping: Record<DateDisplayFormat, string> = {
    local: date.toLocaleDateString(locale, {
      year: 'numeric',
      month: showMonth ? 'numeric' : undefined,
      day: showDay ? 'numeric' : undefined,
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    friendly: date.toLocaleDateString(locale, {
      year: 'numeric',
      month: showMonth ? 'long' : undefined,
      day: showDay ? 'numeric' : undefined,
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    mmddyy: date.toLocaleDateString('en-us', {
      // we choose en-us because it uses mm/dd/yyyy
      year: 'numeric',
      month: showMonth ? '2-digit' : undefined,
      day: showDay ? '2-digit' : undefined,
      hour: showTime ? '2-digit' : undefined,
      hourCycle: showTime ? 'h24' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    ddmmyy: date.toLocaleDateString('en-gb', {
      // we choose en-gb because it uses dd/mm/yyyy
      year: 'numeric',
      month: showMonth ? '2-digit' : undefined,
      day: showDay ? '2-digit' : undefined,
      hour: showTime ? '2-digit' : undefined,
      hourCycle: showTime ? 'h24' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    iso: showTime
      ? date.toISOString().slice(0, 10) +
        ' ' +
        date.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: timezone,
        })
      : isoStringNoTime[displayAs],
    unix: Math.floor(date.getTime() / 1000).toString(),
  }
  return mapping[dateDisplayFormat]
}

/**
 * Formats a duration object and translates it to human language.
 * If available, it uses the `Intl.DurationFormat` API.
 * If not available, we only return the English version.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat/format
 * @param {Record<string, number>} duration A duration object.
 * @returns {string} The formatted duration.
 */
function getFormattedDuration(duration: Record<string, number>): string {
  /**
   * Use `Intl.DurationFormat` if supported.
   * @see https://caniuse.com/mdn-javascript_builtins_intl_durationformat_format
   */
  if (Intl != null && 'DurationFormat' in Intl) {
    // @ts-expect-error: we already checked if Intl.DurationFormat is supported
    return new Intl.DurationFormat(getHyphenatedCurrentLocale(), { style: 'long' }).format(duration)
  }

  /**
   * If `Intl.DurationFormat` is not supported,
   * we return a simplified formatted duration in English.
   */
  const parts: string[] = []
  const durationKeys = [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
    'microseconds',
    'nanoseconds',
  ]
  for (const durationKey of durationKeys) {
    if (duration[durationKey]) {
      parts.push(`${duration[durationKey]} ${duration[durationKey] > 1 ? durationKey : durationKey.slice(0, -1)}`)
    }
  }
  if (parts.length === 0) throw new Error()
  if (parts.length === 1) return parts[0]
  return `${parts.slice(0, -1).join(', ')} and ${parts.slice(-1)}`
}

const formatTimeInMmSs = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0')
  return `${minutes}:${seconds}`
}

export {
  formatTimeInMmSs,
  getCustomDateFieldStringForDisplay,
  getDateStringForDisplay,
  getFormattedDuration,
  secondsToSemiColonedText,
}
